@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{

    .wrapper{
        @apply w-[90%] mx-auto overflow-hidden max-w-screen-xl;
    }

    .button{
        @apply bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 block w-max py-2 px-6 rounded-lg;
    }
    .bg-page{
        @apply bg-gradient-to-r bg-gray-100 bg-gray-100 bg-gray-100;
    }
    .bg-gradient  {
  
        @apply bg-gradient-to-tl from-teal-400 to-gray-800;
    }

    .footer-area{
        grid-template-areas:
        "form"
        "navigation"
        "social-media"
        "logo"
        "copy";
    }
    
    .footer-area-md{
        grid-template-areas:
        "logo           navigation  form"
        "social-media   navigation  copy"
        ;
    }
}